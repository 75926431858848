<template>
    <div>

    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  metaInfo() {
    return { title: this.$t("mealsystem.meta.meals_menu") };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("mealsystem.meta.meals_menu"),
      },
    ]);
  }
};
</script>